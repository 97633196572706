import Vue from 'vue';
import Vant from 'vant';
import 'vant/lib/index.css';
// import {
// 	parseTime
// } from "../../../../utils/convert";
Vue.use(Vant);
export default {
	name: "csiiLifestyleList",
	data() {
		return {
			isEmpty: true,
			userUuid: '',
			token: '',
			patientUuid: '',
			CsiiDetail: [],
			updateTime: '',
			csiiLifeMedicalForm: {
				userUuid: null,
			},
			list: [],
			loading: false,
			finished: false,
			page: 1,
			pageSize: 10
		}
	},
	mounted() {
		this.token = this.$route.query.token;
		// console.log(this.token)
		this.addDate();
		this.patientUuid = this.$route.query.patientUuid;
		this.loadGnrhMedical(false);
	},
	methods: {
		gotoAdd() {
			//debugger;
			this.$axios.get('/api/api/patientCsiiLifestyleRecord/findByToday/' + this.patientUuid).then(res => {
				if (res.data.code === 200) {
					console.log(this.res)
					this.CsiiDetail = res.data.data;
					if (this.CsiiDetail == null) {
						this.$set(this.csiiLifeMedicalForm, "userUuid", this.patientUuid);
						this.$axios({
							method: "post",
							url: "/api/api/patientCsiiLifestyleRecord/create",
							data: this.csiiLifeMedicalForm
						}).then(res => {
							if (res.data.code === 200) {
								this.CsiiDetail = res.data.data;

								this.$router.push({
									path: "/AddCsiiLifestyleList",
									query: {
										patientUuid: this.patientUuid,
										uuid: this.CsiiDetail.uuid,
										CsiiDetail: this.CsiiDetail,
										condition: 1,
									}
								});
							} else {
								this.$message({
									message: res.data.message,
									type: "error"
								});
							}
						});
					} else {
						this.$router.push({
							path: "/AddCsiiLifestyleList",
							query: {
								patientUuid: this.patientUuid,
								uuid: this.CsiiDetail.uuid,
								CsiiDetail: this.CsiiDetail,
								condition: 1,
							}
						});
					}
					
				} else {
					this.$message({
						message: res.data.message,
						type: "error"
					});
				}
			})
		},
		onLoad() {
			this.page = this.page + 1;
			this.loadGnrhMedical(true);
		},
		addDate() {
			const nowDate = new Date();
			const date = {
				year: nowDate.getFullYear(),
				month: nowDate.getMonth() + 1,
				date: nowDate.getDate(),
			}
			const newmonth = date.month > 10 ? date.month : '0' + date.month
			const day = date.date > 10 ? date.date : '0' + date.date
			this.updateTime = date.year + '-' + newmonth + '-' + day
		},
		gotoDetail(item) {
			let createTime = item.createTime.split(' ')[0];
			if (this.updateTime == createTime) {
				this.$router.push({
					path: "/AddCsiiLifestyleList",
					query: {
						patientUuid: this.patientUuid,
						uuid: item.uuid,
						// CsiiDetail: this.CsiiDetail,
						condition: 1,
					}
				});
			} else {
				this.$router.push({
					path: "/AddCsiiLifestyleList",
					query: {
						patientUuid: this.patientUuid,
						uuid: item.uuid,
						condition: 1,

					}
				});
			}
		},
		loadGnrhMedical(isGetMore) {
			let that = this;
			let param = {
				page: that.page,
				size: that.pageSize,
				userUuid: that.patientUuid
			}

			if (!isGetMore) {
				that.list.splice(0, that.list.length);
			}

			console.log("param", param);

			that.$axios({
				method: "post",
				url: "/api/api/patientCsiiLifestyleRecord/page",
				data: param
			}).then(res => {
				console.log("pageres", res);
				if (res.data.code === 200) {
					if (res.data.data !== null && res.data.data.length > 0) {
						that.isEmpty = false;
						that.loading = false;

						let arr = [];
						res.data.data.forEach(r => {
							// r.createTime = parseTime(r.createTime);
							arr.push(r);
						})
						that.list = that.list.concat(arr);
						console.log("11", that.list)
					} else {
						that.finished = true;
					}
				}
			});
		},
	}
}
